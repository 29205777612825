import gql from 'graphql-tag';
export const gqlRequestConfig = {
  query: {
    GET_REQUEST_CONFIG: gql`
      query requestConfig($userId: String!) {
        getUserNotification(userId: $userId) {
          notification {
            name
            message
            category
            type
            close
            viewed_by
            recipient_type
            recipients
            status
            link {
              internal
              url
            }
            _id
          }
        }
        getUserPermissions(userId: $userId) {
          master
          supportRemote
          accessConfig
          accessData
          teamsGateway
          permissions
        }
      }
    `
  },
  mutation: {
    CLOSE_NOTIFICATION: gql`
      mutation closeNotification($userId: String!, $notificationId: String!) {
        closeNotification(userId: $userId, notificationId: $notificationId)
      }
    `
  }
};
