import sz_lang from '@helpers/translate';
import { isEmail, isURL, isURLWithPlaceholer } from '@helpers/utils';
const { translate } = sz_lang();
const {
  description,
  flow,
  control,
  period,
  page,
  team,
  default_team,
  user,
  name,
  password,
  botname,
  botlink,
  host,
  port,
  provider,
  protocol,
  channel_screen,
  translateRequired,
  translateIncompleteNumber,
  translateCanBeNoSpace,
  translateInvalid,
  email,
  update_interval,
  url,
  account_name,
  ra_page_id,
  account_password,
  version,
  token,
  type,
  file,
  title,
  footer,
  category,
  type_action,
  list,
  caption,
  number,
  variable,
  message_box,
  interval,
  form_title,
  button_text,
  message,
  messages,
  option_title,
  list_title,
  input_fields,
  quota,
  start_hour,
  end_hour,
  method,
  ending_message,
  header
} = translate.value;

export const formValidate = () => {
  return {
    description: [
      (val: any) => !!val || translateRequired({ name: description })
    ],
    flow: [(val: any) => !!val || translateRequired({ name: flow })],
    generic_type: [
      (val: any) =>
        !!val ||
        translateRequired({ name: channel_screen.messages.generic_type })
    ],
    footer: [(val: any) => !!val || translateRequired({ name: footer })],
    method: [(val: any) => !!val || translateRequired({ name: method })],
    button_text: [
      (val: any) => !!val || translateRequired({ name: button_text })
    ],
    list: [(val: any) => !!val || translateRequired({ name: list })],
    category: [(val: any) => !!val || translateRequired({ name: category })],
    type_action: [(val: any) => !!val || translateRequired({ name: type_action })],
    control: [(val: any) => !!val || translateRequired({ name: control })],
    period: [(val: any) => !!val || translateRequired({ name: period })],
    default_team: [
      (val: any) => !!val || translateRequired({ name: default_team })
    ],
    team: [(val: any) => !!val || translateRequired({ name: team })],
    configuration_bsp: [
      (val: any) =>
        !!val ||
        translateRequired({ name: channel_screen.messages.configuration_bsp })
    ],
    configuration_auth: [
      (val: any) =>
        !!val ||
        val === false ||
        translateRequired({
          name: channel_screen.my_business.configuration_auth
        })
    ],
    caption: [(val: any) => !!val || translateRequired({ name: caption })],
    message_box: [
      (val: any) => !!val || translateRequired({ name: message_box })
    ],
    form_title: [
      (val: any) => !!val || translateRequired({ name: form_title })
    ],
    message: [(val: any) => !!val || translateRequired({ name: message })],
    ending_message: [
      (val: any) => !!val || translateRequired({ name: ending_message })
    ],
    youtubeQuota: [
      (val: any) => {
        const value = parseInt(val);
        return value >= 10000 || translateInvalid({ name: quota });
      }
    ],
    interval: [
      (val: any) => {
        const value = parseInt(val);
        return value >= 30 || translateInvalid({ name: interval });
      }
    ],
    update_interval: [
      (val: any) => {
        const value = parseInt(val);
        return value >= 30 || translateInvalid({ name: update_interval });
      }
    ],
    title: [(val: any) => !!val || translateRequired({ name: title })],
    file: [(val: any) => !!val || translateRequired({ name: file })],
    version: [(val: any) => !!val || translateRequired({ name: version })],
    type: [(val: any) => !!val || translateRequired({ name: type })],
    page: [(val: any) => !!val || translateRequired({ name: page })],
    number: [
      (val: any) => !!val || translateRequired({ name: number }),
      (val: any) =>
        !(val.split(' ').length > 1) || translateCanBeNoSpace({ name: number })
    ],
    phone_number: [
      (val: any) => !!val || translateRequired({ name: number }),
      (val: any) =>
        !(val.split(' ').length > 1) || translateCanBeNoSpace({ name: number }),
      (val: any) =>
        (val.length >= 12 && val.length <= 13) || translateIncompleteNumber()
    ],
    token: [(val: any) => !!val || translateRequired({ name: token })],
    botname: [(val: any) => !!val || translateRequired({ name: botname })],
    host: [(val: any) => !!val || translateRequired({ name: host })],
    provider: [(val: any) => !!val || translateRequired({ name: provider })],
    port: [(val: any) => !!val || translateRequired({ name: port })],
    quota: [(val: any) => !!val || translateRequired({ name: quota })],
    start_hour: [
      (val: any) => !!val || translateRequired({ name: start_hour })
    ],
    end_hour: [(val: any) => !!val || translateRequired({ name: end_hour })],
    name: [(val: any) => !!val || translateRequired({ name: name })],
    option_title: [
      (val: any) => !!val || translateRequired({ name: option_title })
    ],
    list_title: [
      (val: any) => !!val || translateRequired({ name: list_title })
    ],
    name_hsm: [
      (val: any) => !!val || translateRequired({ name: name }),
      (v: any) => !!/^[a-z0-9_]+$/.test(v) || messages.invalid_character
    ],
    password: [(val: any) => !!val || translateRequired({ name: password })],
    variable: [(val: any) => !!val || translateRequired({ name: variable })],
    account_password: [
      (val: any) => !!val || translateRequired({ name: account_password })
    ],
    MicrosoftAppPassword: [
      (val: any) =>
        !!val ||
        translateRequired({
          name: channel_screen.teams.microsoft_app_password
        })
    ],
    MicrosoftAppId: [
      (val: any) =>
        !!val ||
        translateRequired({ name: channel_screen.teams.microsoft_app_id })
    ],
    protocol: [(v: any) => !!v || translateRequired({ name: protocol })],
    user: [(v: any) => !!v || translateRequired({ name: user })],
    url: [
      (v: any) => !!v || translateRequired({ name: url }),
      (v: any) => isURL(v) || translateInvalid({ name: url })
    ],
    url_template_action: [
      (v: any) => !!v || translateRequired({ name: url }),
      (v: any) => isURL(v) || translateInvalid({ name: url }),
      (v: any) => isURLWithPlaceholer(v) || translateInvalid({ name: url })
    ],
    botlink: [
      (v: any) => !!v || translateRequired({ name: botlink }),
      (v: any) => isURL(v) || translateInvalid({ name: botlink })
    ],
    email: [
      (v: any) => !!v || translateRequired({ name: email }),
      (v: any) => isEmail(v) || translateInvalid({ name: email })
    ],
    account_name: [
      (v: any) => !!v || translateRequired({ name: account_name })
    ],
    ra_page_id: [(v: any) => !!v || translateRequired({ name: ra_page_id })],
    input_fields: [
      (v: any) => {
        return !!v || v === false || translateRequired({ name: input_fields });
      }
    ],
    header: [(val: any) => !!val || translateRequired({ name: header })]
  };
};
