import gql from 'graphql-tag';
import { PAGINATION } from './fragments';
export const gqlTeam = {
  query: {
    GET_LIST_SELECT_TEAMS: gql`
      ${PAGINATION}
      query {
        teams {
          meta {
            ...PAGINATION
          }
          items {
            name
            _id
          }
        }
      }
    `,
    SEARCH_TEAMS: gql`
      query searchTeams($search: String!) {
        teams(search: $search, limit: 100) {
          items {
            name
            _id
          }
        }
      }
    `,
    SEARCH_TEAMS_BY_ID: gql`
      query searchTeams($search: String!) {
        teams(search: $search, limit: 100, column: "_id") {
          items {
            name
            _id
          }
        }
      }
    `,
    GET_TEAMS_PAGINATION: gql`
      ${PAGINATION}
      query pagination_teams($page: Int!, $limit: Int!) {
        teams(page: $page, limit: $limit) {
          meta {
            ...PAGINATION
          }
          items {
            name
            _id
          }
        }
      }
    `
  }
};
