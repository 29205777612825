import gql from 'graphql-tag';
import { HSM_TEMPLATES, PAGINATION } from './fragments';

export const gqlHSM = {
  query: {
    CHECK_HSM_BROKER: gql`
    query countExistentChannelWithBroker($numbers: [String!]!) {
      countExistentChannelWithBroker(numbers: $numbers)
    }
    `,
    GET_CHECK_CHANNEL_NOT_DIALOG: gql`
      query {
        checkChannelsNotDialog
      }
    `,
    GET_ALL_HSM: gql`
      ${PAGINATION}
      ${HSM_TEMPLATES}

      query {
        additionalFields(limit: 100) {
          items {
            name
            _id
          }
        }
        predefinedStaticFields {
          name
        }
        hsmTemplates {
          meta {
            ...PAGINATION
          }
          items {
            ...HSM_TEMPLATES
          }
        }
        getPlatformsDefault {
          platform
          icon
        }
        hsmChannels {
          meta {
            ...PAGINATION
          }
          items {
            _id
            description
            platform
            provider
            whatsapp_broker
            number
            status
          }
        }
      }
    `,
    GET_HSM_PAGINATION: gql`
      ${PAGINATION}
      ${HSM_TEMPLATES}
      query pagination_hsm(
        $page: Int!
        $limit: Int!
        $search: String!
        $searchOptions: TemplateFiltersInput
      ) {
        hsmTemplates(
          page: $page
          limit: $limit
          search: $search
          searchOptions: $searchOptions
        ) {
          meta {
            ...PAGINATION
          }
          items {
            ...HSM_TEMPLATES
          }
        }
      }
    `,
    SEARCH_HSM: gql`
      ${PAGINATION}
      ${HSM_TEMPLATES}
      query searchHsmTemplates(
        $search: String!
        $searchOptions: TemplateFiltersInput!
        $limit: Int
      ) {
        hsmTemplates(
          search: $search
          searchOptions: $searchOptions
          limit: $limit
        ) {
          meta {
            ...PAGINATION
          }
          items {
            ...HSM_TEMPLATES
          }
        }
      }
    `,
    GET_HSM_CHANNELS: gql`
      ${PAGINATION}
      query searchHsmChannels($search: String!) {
        hsmChannels(search: $search, limit: 50) {
          meta {
            ...PAGINATION
          }
          items {
            _id
            description
            platform
            provider
            whatsapp_broker
            number
            status
          }
        }
      }
    `,
    GET_SELECT_DEVICES_PAGINATION: gql`
      ${PAGINATION}
      query searchHsmChannels($page: Int!, $limit: Int!) {
        hsmChannels(page: $page, limit: $limit, search: "") {
          meta {
            ...PAGINATION
          }
          items {
            _id
            description
            platform
            provider
            whatsapp_broker
            number
            status
          }
        }
      }
    `
  },
  mutation: {
    SAVE_HSM: gql`
      mutation saveHsmTemplate($template: SaveHsmTemplateInput!) {
        saveHsmTemplate(template: $template) {
          _id
        }
      }
    `,
    DELETE_HSM: gql`
      mutation removeHsmTemplate($id: [String!]!) {
        removeHsmTemplate(id: $id)
      }
    `
  }
};
