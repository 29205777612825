import gql from 'graphql-tag';

export const gqlPlatform = {
  query: {
    GET_PLATFORMS: gql`
      query {
        getPlatformsDefault {
          name
          platform
          placeholder
          icon
          componentsBlockedInFlow
        }
      }
    `
  }
};
