import gql from 'graphql-tag';
import { PAGINATION } from './fragments';
export const gqlFlow = {
  query: {
    GET_LIST_SELECT_FLOW: gql`
      ${PAGINATION}
      query {
        flows {
          meta {
            ...PAGINATION
          }
          items {
            name
            _id
          }
        }
      }
    `,
    SEARCH_FLOWS: gql`
      query searchFlows($search: String!) {
        flows(search: $search, limit: 100) {
          items {
            name
            _id
          }
        }
      }
    `,
    GET_FLOWS_PAGINATION: gql`
      ${PAGINATION}
      query pagination_flows($page: Int!, $limit: Int!) {
        flows(page: $page, limit: $limit) {
          meta {
            ...PAGINATION
          }
          items {
            name
            _id
          }
        }
      }
    `
  }
};
