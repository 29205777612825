import gql from 'graphql-tag';
import { PAGINATION } from '../fragments';

export default {
  GET_TIME_GROUP_PAGINATED: gql`
    ${PAGINATION}
    query getTimeGroupsPaginated($page: Int, $limit: Int, $search: String) {
      getTimeGroupsPaginated(page: $page, limit: $limit, search: $search) {
        meta {
          ...PAGINATION
        }
        items {
          _id
          name
          group {
            description
            finalDay
            finalMonth
            finalTime
            finalWeek
            initialDay
            initialMonth
            initialTime
            initialWeek
          }
          used_by {
            voice {
              id
              description
              type
            }
            engage {
              id
              description
              type
            }
            szchat {
              id
              description
              type
            }
          }
        }
      }
    }
  `,
  GET_TIME_GROUP: gql`
    query getTimeGroup($id: [String!]!) {
      getTimeGroup(id: $id) {
        _id
        name
        group {
          description
          finalDay
          finalMonth
          finalTime
          finalWeek
          initialDay
          initialMonth
          initialTime
          initialWeek
        }
        used_by {
          voice {
            id
            description
            type
          }
          engage {
            id
            description
            type
          }
          szchat {
            id
            description
            type
          }
        }
      }
    }
  `
};
