import { ApolloClient, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { szRoutes } from '@helpers/SzRoutes';
import { getToken, removeSessionUser } from '@helpers/utils';
import { Icommon } from '@models/general';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { provide } from '@vue/composition-api';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { boot } from 'quasar/wrappers';
import VueApollo from 'vue-apollo';

const authLink = setContext((_: any, { headers }: Icommon) => {
  return {
    headers: {
      ...headers,
      Authorization: getToken()
    }
  };
});

const link = createUploadLink({
  uri: `${window.location.origin}/graphql`
});

const cache = new InMemoryCache({ addTypename: false }) as any;

const $persistor = new CachePersistor({
  cache,
  storage: window.localStorage as PersistentStorage<
    PersistedData<NormalizedCacheObject>
  >,
  trigger: 'write',
  key: 'sz_cache',
  maxSize: false,
  serialize: true
});

const defaultOptions: any = {
  query: {
    fetchPolicy: 'no-cache'
  },
  mutate: {
    fetchPolicy: 'no-cache'
  }
};

const jwtExpiredLogout = () => {
  removeSessionUser();
};

const errorLink = onError(({ graphQLErrors }: Icommon) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((item: any) => {
      switch (item.message) {
        case 'Unauthorized':
        case 'jwt expired':
          jwtExpiredLogout();
          break;
        case 'Need renew password':
          location.href = szRoutes.login.redefine_password;
          break;
      }
    });
  }
});

const apolloClient = new ApolloClient({
  cache,
  link: authLink
    .concat((errorLink as unknown) as ApolloLink)
    .concat(authLink.concat(link)),
  defaultOptions
});

export default boot(({ app, Vue }: Icommon) => {
  app.setup = () => {
    Vue.use(VueApollo);
    provide(DefaultApolloClient, apolloClient);
    return {};
  };
});

const $apollo: Icommon = apolloClient;

export { $apollo, $persistor };
