import { boot } from 'quasar/wrappers';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../i18n/index';

declare module 'vue-i18n/types' {
  export default class VueI18n {
    t(key: Path, values?: Values): string;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    i18n: VueI18n;
    $t: typeof VueI18n.prototype.t;
  }

  interface VueConstructor<V extends Vue = Vue> {
    i18n: typeof VueI18n.prototype;
  }
}

Vue.use(VueI18n);

const langStorage = localStorage.getItem('sz_language');

let lang = 'pt-BR';

if (langStorage) {
  lang = langStorage;
}

localStorage.setItem('sz_language', lang);

export const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages
});

export default boot(({ app }) => {
  app.i18n = i18n;
});
