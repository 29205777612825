import gql from 'graphql-tag';

export const PAGINATION = gql`
  fragment PAGINATION on PageInfo {
    totalPages
    totalItems
    itemCount
    itemsPerPage
    currentPage
  }
`;

const EMAIL = gql`
  fragment EMAIL on EmailConfig {
    host
    port
    provider
    protocol
    username
    password
  }
`;

const HEADERS_GENERIC = gql`
  fragment HEADERS_GENERIC on HeadersConfig {
    data {
      name
      value
    }
    mode
  }
`;

export const WEBCHAT_CUSTOMIZATION_V1 = gql`
  fragment WEBCHAT_CUSTOMIZATION_V1 on ChannelEntity {
    times
    title
    button
    colorDescription
    colorName
    colorSend
    colorUpload
    colorText
    initAutomatic
    text
    icon
    iconUpload
    type
    agent_description
    messageInitAutomatic
    inputs
    label
    photo_agent
    upload
  }
`;

export const WEBCHAT_CUSTOMIZATION_V2 = gql`
  fragment WEBCHAT_CUSTOMIZATION_V2 on CustomizationWebchatV2 {
    title
    subtitle
    input_message
    finish_message
    closeAfterFinish
    keepKeyboardOpen
    avatar
    online
    showOnline
    delay
    onlineCampaigns
    animation
    locale
    button {
      animation
      color
      type
      icon
      src
      delay
      interval
      repeat
    }
    welcomeMessage {
      enabled
      text
    }
    theme {
      primary
      secondary
      text
    }
    pwa {
      enabled
      app_name
      icon
    }
    userForm {
      enabled
      title
      notification
      fields {
        name
        label
        customLabel
        required
        type
        typeVariable
        checked
      }
    }
    label {
      enabled
      text
      theme
      delay
      closable
      showWhenOffline
    }
    alert {
      color
    }
  }
`;

export const CHANNELS = gql`
  ${WEBCHAT_CUSTOMIZATION_V2}
  ${WEBCHAT_CUSTOMIZATION_V1}
  ${EMAIL}
  ${HEADERS_GENERIC}

  fragment CHANNELS on ChannelEntity {
    options {
      ...WEBCHAT_CUSTOMIZATION_V2
    }
    ...WEBCHAT_CUSTOMIZATION_V1
    description
    status
    flow_id
    webchat_version
    receptive
    enabled
    username
    botname
    batch_delivery
    teams_gtw
    ig_direct_token
    ig_direct_username
    ig_direct_id
    ig_direct_page_id
    facebook_post_id
    provider
    authenticationType
    authUrlCode
    smtp {
      ...EMAIL
    }
    imap {
      ...EMAIL
    }
    headers {
      ...HEADERS_GENERIC
    }
    email_rules {
      rules {
        default_team
        teams {
          email
          team
        }
      }
      automatic_response {
        enable
        message
      }
    }
    messenger_id
    api_key
    botlink
    fbPostPeriod
    fbPostcontrol
    igCommentsPeriod
    fbPostcontrol
    igCommentsControl
    name
    MicrosoftAppId
    ra_page_name
    youtube_control
    quota
    start_hour
    end_hour
    interval
    update_interval
    ra_page_id
    instagram_username
    instagram_comment_user_token
    instagram_comment_ad_account_id
    igCommentsId
    igCommentsControl
    msg_send_type
    url
    photo
    auth_method
    country
    number
    auth_request_fields {
      scopes
      client_secret
      isRefreshToken
      client_id
      url_request_token
      url_exchange_token
      url_refresh_token
      email
      keyId
      key
      url
    }
    params {
      mode
      data {
        name
        value
      }
    }
    platform
    platform_color
    send_with_link
    msg_spacing
    msg_send_time
    created_at
    updated_at
    whatsapp_broker
    whatsapp_sandbox
    generic_type
    webhook_inbound {
      enabled
      script
    }
    webhook_outbound {
      enabled
      script
    }
    flow {
      name
      _id
    }
    authUrl
    _id
  }
`;

export const HUB_FACEBOOK = gql`
  fragment HUB_FACEBOOK on HubFacebookEntity {
    page_id
    _id
    is_private
    name
    instagram_id
    old_token_messenger
    old_token_ig_direct
    old_token_ig_comments
    old_token_fb_comments
    token
    user_id
    username
    user_token
  }
`;

export const PAGES_FACEBOOK = gql`
  fragment PAGES_FACEBOOK on FacebookPagesEntity {
    _id
    page_id
    name
    type
    token
    user_id
  }
`;

export const PAGES_INSTAGRAM = gql`
  fragment PAGES_INSTAGRAM on InstagramPagesEntity {
    _id
    page_id
    instagram_id
    name
    token
    user_token
    user_id
    username
    is_private
    profile_pic
  }
`;

export const PAGES_INSTAGRAM_DIRECT = gql`
  fragment PAGES_INSTAGRAM_DIRECT on InstagramDirectPagesEntity {
    _id
    page_id
    instagram_id
    name
    token
    user_id
    username
    is_private
    profile_pic
  }
`;

export const HSM_TEMPLATES = gql`
  fragment HSM_TEMPLATES on HsmTemplateEntity {
    _id
    name
    category
    visibility
    language
    header_type
    header
    header_example
    header_filename
    message
    footer
    button_type
    previous_category
    updated_at
    buttons {
      text
      type
      url
      phone_number
    }
    authentication {
      advisory
      expires {
        active
        time
      }
    }
    placeholders {
      placeholder
      variable
      example
      context
    }
    devices {
      channel
      description
      platform
      number
      namespace
      whatsapp_broker
      active
      hub_status
      hub_status_message
    }
    interactives {
      type
      button
      sections {
        title
        rows {
          id
          title
          description
        }
      }
    }
    meta_rules {
      updates
      update_expiration_date
      updated_at
    }
  }
`;
