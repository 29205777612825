import gql from 'graphql-tag';
export const gqlMarketplace = {
  query: {
    GET_INTEGRATIONS: gql`
      query {
        getIntegrations {
          _id
          id
          integrated
          title {
            pt_BR
            en_US
            es_ES
          }
          type
          tags {
            pt_BR
            en_US
            es_ES
          }
          imageCard
          contentTabs {
            type
            titleTab {
              pt_BR
              en_US
              es_ES
            }
            favicon
            headerImage
            text {
              pt_BR
              en_US
              es_ES
            }
            action
            schema {
              type
              head {
                type
                title {
                  pt_BR
                  en_US
                  es_ES
                }
              }
              rows {
                type
                title {
                  pt_BR
                  en_US
                  es_ES
                }
                key
                actived
              }
            }
          }
        }
      }
    `
  },
  mutation: {
    SAVE_INTEGRATIONS: gql`
      mutation saveIntegrations($integrations: SaveIntegrationsInput!) {
        saveIntegrations(integrations: $integrations) {
          _id
        }
      }
    `
  }
};
