export const szRoutes = {
  docs: {
    default: `${window.location.origin}/docs`,
    generic: `${window.location.origin}/docs/pt-br/messages/generic_channel`,
    gupshup: `${window.location.origin}/docs/pt-br/modules/gupshup`,
    channel: `${window.location.origin}/docs/pt-br/channels`,
    marketplace: `${window.location.origin}/docs/pt-br/overview`,
    hsm: `${window.location.origin}/docs/pt-br/modules/template_message`
  },
  login: {
    default: '/signin',
    logout: '/logout',
    forgot_password: '/forgot_password',
    redefine_password: '/redefine_password'
  },
  dashboard: {
    indicators: { default: `${window.location.origin}/dashboard` },
    storage: { default: `${window.location.origin}/config/storage` },
    monitoring: { default: `${window.location.origin}/monitoring` }
  },
  reports: {
    attendances: { default: `${window.location.origin}/reports/attendances` },
    agents: { default: `${window.location.origin}/reports/agents` },
    conversation: { default: `${window.location.origin}/reports/messages` },
    extract: { default: `${window.location.origin}/reports/license` },
    audit: { default: `${window.location.origin}/reports/audits` },
    variables: { default: `${window.location.origin}/reports/inputs` },
    batch_delivery: {
      default: `${window.location.origin}/reports/batchDelivery`
    },
    export: { default: `${window.location.origin}/reports/exports` },
    premium: { default: `${window.location.origin}/reports/premium` }
  },
  settings: {
    administrators: {
      default: '/admins',
      sz4: `${window.location.origin}/admins`
    },
    agents: { default: `${window.location.origin}/agents` },
    aplication: { default: `${window.location.origin}/config/app` },
    team: { default: `${window.location.origin}/campaigns` },
    additional_fields: { default: `${window.location.origin}/contacts/fields` },
    word_filter: { default: `${window.location.origin}/config/wordFilter` },
    custom_variables: {
      default: `${window.location.origin}/config/customFields`
    },
    time_group: {
      default: '/config/timeGroup',
      sz4: `${window.location.origin}/config/timeGroup`
    },
    user_group: { default: `${window.location.origin}/config/group` },
    hsm: { default: `${window.location.origin}/hsm` },
    hsm_template: { default: '/hsm_template' },
    import_contacts: {
      default: `${window.location.origin}/services/contacts/mailing`
    },
    multichannel: {
      default: `${window.location.origin}/services/api/contactUs`
    },
    notification: { default: `${window.location.origin}/license/config` },
    pauses: { default: `${window.location.origin}/config/pauses` },
    remote_support: {
      default: `${window.location.origin}/config/remoteSupport`
    },
    tabulations: { default: `${window.location.origin}/config/tabulations` },
    tags: { default: `${window.location.origin}/tags` },
    security_privacy: {
      default: `${window.location.origin}/config/securityPrivacy`
    },
    artificial_intelligence: { default: `${window.location.origin}/nlp` },
    contexts: { default: `${window.location.origin}/contexts` }
  },
  marketplace: {
    default: '/marketplace'
  },
  integrations: {
    api: { default: `${window.location.origin}/services/api` },
    rest: { default: `${window.location.origin}/services/rest` },
    third: { default: `${window.location.origin}/services/integration` },
    rd_station: { default: `${window.location.origin}/services/rdstation` },
    telephone_exchange: {
      default: `${window.location.origin}/services/callcente`
    }
  },
  flow: { default: '/channels/flow' },
  channels: {
    default: '/channels',
    routeList: '/channels',
    routeSearch: '/channels/list'
  },
  batch: { default: '/batchDelivery' },
  contact: { default: `${window.location.origin}/contacts` },
  help: { default: '/help' },
  about: { default: '/about' },
  active_contact: { default: '/active-contact' }
};
