import { createNamespacedHelpers } from 'vuex-composition-helpers';

const Store = (nameModule: string) => {
  const { useState, useMutations, useActions, useGetters } =
    createNamespacedHelpers(nameModule);

  return { useState, useMutations, useActions, useGetters };
};

export const SigninModule = Store('SigninModule');
export const AdminModule = Store('AdminModule');
export const ChannelModule = Store('ChannelModule');
export const FlowModule = Store('FlowModule');
export const TeamModule = Store('TeamModule');
export const AdditionalFieldsModule = Store('AdditionalFieldsModule');
export const FacebookModule = Store('FacebookModule');
export const InstagramModule = Store('InstagramModule');
export const StorageModule = Store('StorageModule');
export const PermissionModule = Store('PermissionModule');
export const MarketplaceModule = Store('MarketplaceModule');
export const SettingsModule = Store('SettingsModule');
export const HsmModule = Store('HsmModule');
