import gql from 'graphql-tag';

export const gqlSignin = {
  mutation: {
    LOGIN: gql`
      mutation login(
        $email: String!
        $password: String!
        $language: String!
        $recaptchaV3Token: String!
        $recaptchaV2Token: String!
        $ignoreBlocked: Boolean!
      ) {
        login(
          email: $email
          password: $password
          language: $language
          recaptchaV3Token: $recaptchaV3Token
          recaptchaV2Token: $recaptchaV2Token
          ignoreBlocked: $ignoreBlocked
        ) {
          token
          intend
          client_id
          user {
            name
            _id
            email
            type
            photo
          }
        }
      }
    `,
    REDEFINE_PASSWORD: gql`
      mutation redefinePassword(
        $language: String!
        $password: String!
        $repeatPassword: String!
        $oldPassword: String!
      ) {
        redefinePassword(
          language: $language
          password: $password
          repeatPassword: $repeatPassword
          oldPassword: $oldPassword
        ) {
          token
          intend
          client_id
          user {
            name
            _id
            email
            type
            photo
          }
        }
      }
    `,
    LOGIN_SAML_TOKEN: gql`
      mutation oauthSamlToken($token: String!) {
        oauthSamlToken(token: $token) {
          token
          intend
          client_id
          user {
            name
            _id
            email
            type
            photo
          }
        }
      }
    `,
    LOGIN_TOKEN_SUPPORT_REMOTO: gql`
      mutation oauthTmpToken($token: String!) {
        oauthTmpToken(token: $token) {
          token
          intend
          client_id
          user {
            name
            _id
            email
            type
            photo
          }
        }
      }
    `
  }
};
