import gql from 'graphql-tag';

export const gqlStorage = {
  mutation: {
    UPLOAD_FILE: gql`
      mutation uploadFile($file: CreateStorageInput!) {
        uploadFile(file: $file) {
          _id
          type
          name
          size
          filename
        }
      }
    `
  }
};
