import { $apollo, $persistor } from '@boot/apollo';
import { Icommon } from '@models/general';
import * as crypto from 'crypto-js';
import { notification } from './utils';

const hashIdentifier = crypto.MD5(window.origin).toString();
const hashPermission = crypto.MD5(`${window.origin}.permission`).toString();

export const setUserCache = (user: Icommon) => {
  const value = JSON.stringify({ ...user });
  const key = crypto.AES.encrypt(value, window.origin).toString();
  localStorage.setItem('user_email', user?.user?.email);

  $apollo.cache.writeData({
    id: hashIdentifier,
    data: { key }
  });

  $persistor.persist().catch(() => {
    notification('error');
  });
};

export const getUserCache = () => {
  $persistor.restore().catch(() => {
    notification('error');
  });

  const key = $apollo.cache.data.data[hashIdentifier]?.key;

  if (key) {
    const value = crypto.AES.decrypt(key, window.origin).toString(
      crypto.enc.Utf8
    );
    const user = JSON.parse(value);

    if (user.token) {
      return {
        token: user.token,
        intend: user.intend,
        client_id: user.client_id,
        user: {
          name: user.user.name,
          email: user.user.email,
          _id: user.user._id,
          type: user.user.type,
          ...user.user
        }
      };
    }
  }

  return null;
};

export const setPermissionsCache = (permissions: Icommon) => {
  const value = JSON.stringify({ ...permissions });
  const key = crypto.AES.encrypt(value, window.origin).toString();

  $apollo.cache.writeData({
    id: hashPermission,
    data: { key }
  });

  $persistor.persist().catch(() => notification('error'));
};

export const getPermissionCache = () => {
  $persistor.restore().catch(() => notification('error'));

  const key = $apollo.cache.data.data[hashPermission]?.key;

  if (key) {
    const value = crypto.AES.decrypt(key, window.origin).toString(
      crypto.enc.Utf8
    );
    return JSON.parse(value);
  }

  return null;
};
