import gql from 'graphql-tag';
export const gqlLoginSlides = {
  query: {
    GET_LOGIN_SLIDES: gql`
      query {
        getLoginSlides {
          name
          category
          alignment
          exibitionTime
          exibitionOrder
          slideBackground {
            fontSize
            backgroundColor
            imageUrl
            clickUrl
          }
          title {
            fontSize
            color
            value
          }
          text {
            fontSize
            color
            value
          }
          button {
            fontSize
            color
            value
            backgroundColor
            url
          }
          whitelabelId
        }
      }
    `
  }
};
