









import { getFaviconWhitelabel } from '@helpers/utils';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'App',
  meta() {
    return {
      ...getFaviconWhitelabel()
    };
  },
  setup() {
    const loading = ref(true);

    const interval = setInterval(() => {
      let value = document.readyState === 'complete' ? false : true;
      if (!value) {
        loading.value = value;
      }
    }, 1000);

    watch(
      () => loading.value,
      () => {
        clearInterval(interval);
      }
    );

    const configUnleash = computed(() => {
      return {
        appName: 'default',
        refreshInterval: 60,
        environment: 'production',
        clientKey: '5W4itpGDwx1OsQTKO78xs6R1Rq8mYSqz',
        url: 'https://unleash-proxy-xsvt6f6n2q-uc.a.run.app/proxy'
      };
    });

    return { loading, configUnleash };
  }
});
