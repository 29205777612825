import { szRoutes } from '@helpers/SzRoutes';
import sz_lang from '@helpers/translate';
import { i18n } from '@boot/i18n';
import { getPermissionCache } from './cache';
import { getStorage } from './utils';
import { IMenuItem } from '@sz4/sz-components/src/stories/components/SzMenuItem';
import { Ref, computed } from '@vue/composition-api';
import useSwitcher from '@sz4/sz-components/src/composables/useSwitcher';

const {
  dashboard,
  reports,
  flow,
  channels,
  batch,
  contact,
  help,
  docs,
  about,
  settings,
  integrations,
  marketplace
} = szRoutes;

function checkPermission(name: string) {
  const { master, permissions } = getPermissionCache();
  if (master) {
    return master;
  }
  return !(permissions.indexOf(name) < 0);
}

export default function(existsChannelsNotDialog: Ref<boolean>) {
  const { translate } = sz_lang();
  const whitelabel = getStorage('sz_whitelabel');
  const currentLang = i18n.locale.toLowerCase();
  let linkChangelog = whitelabel.link_changelog;
  const linkStatus = whitelabel.link_status;

  if (!whitelabel.default) {
    const linkDefault = `/docs/${currentLang}/changelog`;
    linkChangelog = whitelabel?.link_changelog || linkDefault;
  }

  const szMenu = computed(() => {
    const { getFeatureFlag, isReady } = useSwitcher<
      | 'menu_config_administrators'
      | 'voice_menu_config_administrators'
      | 'menu_config_timegroups'
      | 'voice_menu_config_timegroups'
    >();

    const helpMenuOptions = [
      {
        id: 'help-changelog',
        text: translate.value.changelog,
        href: {
          to: linkChangelog,
          target: '_blank'
        }
      },
      {
        id: 'help-support',
        text: translate.value.support,
        href: {
          to: help.default,
          target: '_blank'
        }
      }
    ] as IMenuItem[];

    if (!!linkStatus) {
      helpMenuOptions.push({
        id: 'help-status',
        text: translate.value.status,
        href: {
          to: linkStatus,
          target: '_blank'
        }
      });
    }

    if (whitelabel.show_api_docs) {
      helpMenuOptions.unshift({
        id: 'help-docs',
        text: translate.value.documentation,
        href: {
          to: docs.default,
          target: '_blank'
        }
      });
    }

    const isNewScreenTimeGroup = computed(() =>
      getFeatureFlag('menu_config_timegroups')
    );

    const isNewScreenAdmin = computed(() =>
      getFeatureFlag('menu_config_administrators')
    );

    return isReady.value
      ? ([
          {
            id: 'dashboard',
            text: translate.value.dashboard,
            icon: 'fas fa-tachometer-alt',
            children: [
              {
                id: 'dashboard-dashboard',
                text: translate.value.storage,
                href: dashboard.storage.default,
                permission() {
                  return checkPermission('dashboard');
                }
              },
              {
                id: 'dashboard-storage',
                text: translate.value.indicators,
                href: dashboard.indicators.default,
                permission() {
                  return checkPermission('storage');
                }
              },
              {
                id: 'dashboard-monitoring',
                text: translate.value.monitoring,
                href: dashboard.monitoring.default,
                permission() {
                  return checkPermission('monitoring');
                }
              }
            ]
          },
          {
            id: 'reports',
            text: translate.value.reports,
            icon: 'fas fa-book',
            children: [
              {
                id: 'reports-audits',
                text: translate.value.audit,
                href: reports.audit.default,
                permission() {
                  return checkPermission('reports.audits');
                }
              },
              {
                id: 'reports-attendances',
                text: translate.value.attendances,
                href: reports.attendances.default,
                permission() {
                  return checkPermission('reports.attendances');
                }
              },
              {
                id: 'reports-agents',
                text: translate.value.agents,
                href: reports.agents.default,
                permission() {
                  return checkPermission('reports.agents');
                }
              },
              {
                id: 'reports-messages',
                text: translate.value.conversation,
                href: reports.conversation.default,
                permission() {
                  return checkPermission('reports.messages');
                }
              },
              {
                id: 'reports-batchDelivery',
                text: translate.value.batch,
                href: reports.batch_delivery.default,
                permission() {
                  return checkPermission('reports.batchDelivery');
                }
              },
              {
                id: 'reports-exports',
                text: translate.value.export,
                href: reports.export.default,
                permission() {
                  return checkPermission('reports.exports');
                }
              },
              {
                id: 'reports-messageExtract',
                text: translate.value.message_extract,
                href: reports.extract.default
              },
              {
                id: 'reports-premium',
                text: translate.value.premium,
                href: reports.premium.default,
                permission() {
                  return checkPermission('reports.premium');
                }
              },
              {
                id: 'reports-inputs',
                text: translate.value.variables,
                href: reports.variables.default,
                permission() {
                  return checkPermission('reports.inputs');
                }
              }
            ]
          },
          {
            id: 'channels',
            text: translate.value.channels,
            icon: 'fas fa-list',
            to: channels.default,
            permission() {
              return checkPermission('channels');
            }
          },
          {
            id: 'flow',
            text: translate.value.flow,
            icon: 'fas fa-sitemap',
            href: flow.default,
            permission() {
              return checkPermission('flow');
            }
          },
          {
            id: 'batchDelivery',
            text: translate.value.batch,
            icon: 'fas fa-paper-plane',
            href: batch.default,
            permission() {
              return checkPermission('batchDelivery');
            }
          },
          {
            id: 'contacts',
            text: translate.value.contacts,
            icon: 'fas fa-address-book',
            href: contact.default,
            permission() {
              return checkPermission('contacts');
            }
          },
          {
            id: 'configs',
            text: translate.value.configs,
            icon: 'fas fa-cogs',
            children: [
              {
                id: 'configs-admins',
                text: translate.value.administrators,
                href: isNewScreenAdmin.value
                  ? settings.administrators.default
                  : settings.administrators.sz4,
                permission() {
                  return checkPermission('admins');
                }
              },
              {
                id: 'configs-agents',
                text: translate.value.agents,
                href: settings.agents.default,
                permission() {
                  return checkPermission('agents');
                }
              },
              {
                id: 'configs-app',
                text: translate.value.aplication,
                href: settings.aplication.default,
                permission() {
                  return checkPermission('app');
                }
              },
              {
                id: 'configs-contactFields',
                text: translate.value.additional_fields,
                href: settings.additional_fields.default,
                permission() {
                  return checkPermission('contactFields');
                }
              },
              {
                id: 'configs-contexts',
                text: translate.value.contexts,
                href: settings.contexts.default
              },
              {
                id: 'configs-campaigns',
                text: translate.value.team,
                href: settings.team.default,
                permission() {
                  return checkPermission('campaigns');
                }
              },
              {
                id: 'configs-wordFilter',
                text: translate.value.word_filter,
                href: settings.word_filter.default,
                permission() {
                  return checkPermission('wordFilter');
                }
              },
              {
                id: 'configs-timeGroup',
                text: translate.value.time_group,
                href: isNewScreenTimeGroup.value
                  ? settings.time_group.default
                  : settings.time_group.sz4,
                permission() {
                  return checkPermission('timeGroup');
                }
              },
              {
                id: 'configs-group',
                text: translate.value.user_group,
                href: settings.user_group.default,
                permission() {
                  return checkPermission('group');
                }
              },
              {
                id: 'configs-hsm',
                text: translate.value.hsm,
                href: settings.hsm.default,
                permission() {
                  return (
                    checkPermission('hsm') && existsChannelsNotDialog.value
                  );
                }
              },
              {
                id: 'configs-hsmDefault',
                text: translate.value.hsm_template,
                to: settings.hsm_template.default,
                permission() {
                  return checkPermission('hsm');
                }
              },
              {
                id: 'configs-contactsImport',
                text: translate.value.import_contacts,
                href: settings.import_contacts.default,
                permission() {
                  return checkPermission('contactsImport');
                }
              },
              {
                id: 'configs-ai',
                text: translate.value.artificial_intelligence,
                href: settings.artificial_intelligence.default
              },
              {
                id: 'configs-contactUs',
                text: translate.value.multichannel,
                href: settings.multichannel.default,
                permission() {
                  return checkPermission('contactUs');
                }
              },
              {
                id: 'configs-notification',
                text: translate.value.notification,
                href: settings.notification.default
              },
              {
                id: 'configs-pauses',
                text: translate.value.pauses,
                href: settings.pauses.default,
                permission() {
                  return checkPermission('pauses');
                }
              },
              {
                id: 'configs-securityPrivacy',
                text: translate.value.security_privacy,
                href: settings.security_privacy.default,
                permission() {
                  return checkPermission('securityPrivacy');
                }
              },
              {
                id: 'configs-remoteSupport',
                text: translate.value.remote_support,
                href: settings.remote_support.default,
                permission() {
                  return checkPermission('remoteSupport');
                }
              },
              {
                id: 'configs-tabulations',
                text: translate.value.tabulations,
                href: settings.tabulations.default,
                permission() {
                  return checkPermission('tabulations');
                }
              },
              {
                id: 'configs-tags',
                text: translate.value.tags,
                href: settings.tags.default,
                permission() {
                  return checkPermission('tags');
                }
              },
              {
                id: 'configs-customVariables',
                text: translate.value.custom_variables,
                href: settings.custom_variables.default,
                permission() {
                  return checkPermission('customVariables');
                }
              }
            ]
          },
          {
            id: 'marketplace',
            text: 'Marketplace',
            icon: 'fas fa-store',
            to: marketplace.default
          },
          {
            id: 'integrations',
            text: translate.value.integrations,
            icon: 'fas fa-puzzle-piece',
            children: [
              {
                id: 'integrations-apiInfo',
                text: translate.value.api,
                href: integrations.api.default,
                permission() {
                  return checkPermission('apiInfo');
                }
              },
              {
                id: 'integrations-callcenter',
                text: translate.value.telephone_exchange,
                href: integrations.telephone_exchange.default,
                permission() {
                  return checkPermission('callcenter');
                }
              },
              {
                id: 'integrations-rdstation',
                text: translate.value.rd_station,
                href: integrations.rd_station.default,
                permission() {
                  return checkPermission('rdstation');
                }
              },
              {
                id: 'integrations-rest',
                text: translate.value.rest,
                href: integrations.rest.default,
                permission() {
                  return checkPermission('rest');
                }
              }
            ]
          },
          {
            id: 'help',
            text: translate.value.help,
            icon: 'fas fa-question',
            children: helpMenuOptions
          },
          {
            id: 'about',
            text: translate.value.about,
            icon: 'fas fa-info-circle',
            href: about.default
          }
        ] as IMenuItem[])
      : [];
  });

  return szMenu.value;
}
