// components common
import LoadingScreen from '@common/LoadingScreen.vue';
import Logo from '@common/Logo.vue';
import SelectAdditionalFields from '@common/SelectAdditionalFields.vue';
import SelectFlow from '@common/SelectFlow.vue';
import SelectPageFacebook from '@common/SelectPageFacebook.vue';
import SelectPageInstagram from '@common/SelectPageInstagram.vue';
import SelectPageReclameAqui from '@common/SelectPageReclameAqui.vue';
import SelectTeam from '@common/SelectTeam.vue';
import RequestFields from '@common/RequestFields.vue';
import SelectAuthenticationMethod from '@common/SelectAuthenticationMethod.vue';
import SelectChannels from '@common/SelectChannels.vue';
import SelectChannelDialog from '@common/SelectChannelDialog.vue';
import { boot } from 'quasar/wrappers';

export default boot(({ Vue }) => {
  Vue.component('SelectAdditionalFields', SelectAdditionalFields);
  Vue.component('SelectFlow', SelectFlow);
  Vue.component('SelectChannels', SelectChannels);
  Vue.component('SelectTeam', SelectTeam);
  Vue.component('SelectPageFacebook', SelectPageFacebook);
  Vue.component('SelectPageInstagram', SelectPageInstagram);
  Vue.component('SelectPageReclameAqui', SelectPageReclameAqui);
  Vue.component('SelectAuthenticationMethod', SelectAuthenticationMethod);
  Vue.component('RequestFields', RequestFields);
  Vue.component('LoadingScreen', LoadingScreen);
  Vue.component('SelectChannelDialog', SelectChannelDialog);
  Vue.component('Logo', Logo);
});
