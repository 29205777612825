import gql from 'graphql-tag';
export const gqlWhiteLabel = {
  query: {
    GET_WHITELABEL: gql`
      query {
        getWhitelabel {
          client_id
          brand
          secondaryBrand
          favicon
          system_business
          system_product
          name_product_voice
          voice_favicon
          voice_brand
          voice_logo_switch
          domain
          chargeType
          logo_tech_provider
          bsp_partner_id
          link_changelog
          oauth_gmail_client_secret
          oauth_gmail_app_id
          oauth_office_client_secret
          oauth_office_app_id
          link_status
          default
          show_api_docs
          recaptcha_v3_site_token
          recaptcha_v2_site_token
          host_is_not_recaptcha
          sentry_dsn
          host_ws
          partner_id
          parent_id
          poc
          theme {
            menu
            login
            button
            sidebar
            panel
            table
            modal
          }
        }
      }
    `
  }
};
