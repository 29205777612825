import gql from 'graphql-tag';

export default {
  CREATE_TIME_GROUP: gql`
    mutation createTimeGroup($timeGroup: CreateTimeGroupInput!) {
      createTimeGroup(timeGroup: $timeGroup) {
        _id
        name
        group {
          description
          finalDay
          finalMonth
          finalTime
          finalWeek
          initialDay
          initialMonth
          initialTime
          initialWeek
        }
      }
    }
  `,
  DELETE_TIME_GROUP: gql`
    mutation deleteTimeGroup($ids: [String!]!) {
      deleteTimeGroup(ids: $ids) {
        success
      }
    }
  `,
  UPDATE_TIME_GROUP: gql`
    mutation updateTimeGroup($timeGroup: CreateTimeGroupInput!, $id: String!) {
      updateTimeGroup(timeGroup: $timeGroup, id: $id) {
        name
        group {
          description
          finalDay
          finalMonth
          finalTime
          finalWeek
          initialDay
          initialMonth
          initialTime
          initialWeek
        }
      }
    }
  `
};
