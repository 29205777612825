import { $apollo } from '@boot/apollo';
import { query } from '@graphql';
import { Icommon, IdefinePlatform } from '@models/general';

const getAllPlatforms = async (): Promise<IdefinePlatform[]> => {
  const { data } = await $apollo.query({ query: query.GET_PLATFORMS });
  return data.getPlatformsDefault.map((item: Icommon) => {
    return {
      label: item.name,
      icon: item.icon,
      platform: item.platform
    };
  });
};

const platformBlockedDefault = () => {
  return ['Instagram', 'Whatsapp'];
};

const platformBlockedRemoteSupport = () => {
  return ['Instagram'];
};

const platformBlockedReceptive = () => {
  return [
    'ChatWeb',
    'InstagramComments',
    'FacebookPost',
    'ReclameAqui',
    'YoutubeComments',
    'MercadoLivre'
  ];
};

const platformRestartBlocked = () => {
  return ['InstagramDirect', 'WhatsappBusiness', 'MercadoLivre'];
};

const getListPlatformHub = (listRequest: Icommon[]) => {
  const instagramComments: Icommon[] = [];
  const instagramDirect: Icommon[] = [];
  const facebookPost: Icommon[] = [];
  const facebookMessenger: Icommon[] = [];

  listRequest.forEach(item => {
    if (item.token) {
      facebookPost.push(item);
      facebookMessenger.push(item);
      if (item.instagram_id) {
        instagramDirect.push(item);
        instagramComments.push(item);
      }
    } else {
      if (item.old_token_ig_comments) {
        const token = item.old_token_ig_comments;
        instagramComments.push({ ...item, token, old_token_ig_comments: null });
      }
      if (item.old_token_ig_direct) {
        const token = item.old_token_ig_direct;
        instagramDirect.push({ ...item, token, old_token_ig_direct: null });
      }
      if (item.old_token_fb_comments) {
        const token = item.old_token_fb_comments;
        facebookPost.push({ ...item, token, old_token_fb_comments: null });
      }
      if (item.old_token_messenger) {
        const token = item.old_token_messenger;
        facebookMessenger.push({ ...item, token, old_token_messenger: null });
      }
    }
  });

  return {
    instagramComments,
    instagramDirect,
    facebookPost,
    facebookMessenger
  };
};

export {
  getAllPlatforms,
  platformBlockedDefault,
  platformBlockedRemoteSupport,
  platformBlockedReceptive,
  platformRestartBlocked,
  getListPlatformHub
};
