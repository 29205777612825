import { gqlAdditionalFields } from 'src/graphql/additionalFields';
import { gqlChannel } from 'src/graphql/channels';
import { gqlFlow } from 'src/graphql/flows';
import { gqlGupshup } from 'src/graphql/gupshup';
import { gqlHSM } from 'src/graphql/hsm';
import { gqlLoginSlides } from './getLoginSlides';
import { gqlMarketplace } from 'src/graphql/marketplace';
import { gqlPermission } from 'src/graphql/permission';
import { gqlPlatform } from 'src/graphql/platforms';
import { gqlRequestConfig } from 'src/graphql/requestConfig';
import { gqlSignin } from 'src/graphql/signin';
import { gqlStorage } from 'src/graphql/storage';
import { gqlTeam } from 'src/graphql/team';
import { gqlWhiteLabel } from 'src/graphql/whitelabel';

import { TimeGroupQueries, TimeGroupMutations } from './time_group';
import { AdminsQueries, AdminsMutations } from './admins';

export const query = {
  ...gqlSignin.query,
  ...gqlChannel.query,
  ...gqlPlatform.query,
  ...gqlFlow.query,
  ...gqlTeam.query,
  ...gqlAdditionalFields.query,
  ...gqlWhiteLabel.query,
  ...gqlLoginSlides.query,
  ...gqlPermission.query,
  ...gqlGupshup.query,
  ...gqlHSM.query,
  ...gqlMarketplace.query,
  ...gqlRequestConfig.query,
  ...TimeGroupQueries,
  ...AdminsQueries
};

export const mutation = {
  ...gqlSignin.mutation,
  ...gqlChannel.mutation,
  ...gqlStorage.mutation,
  ...gqlRequestConfig.mutation,
  ...gqlStorage.mutation,
  ...gqlHSM.mutation,
  ...gqlMarketplace.mutation,
  ...gqlLoginSlides.mutation,
  ...TimeGroupMutations,
  ...AdminsMutations
};
