import gql from 'graphql-tag';
import { PAGINATION } from './fragments';
export const gqlAdditionalFields = {
  query: {
    GET_LIST_SELECT_FIELDS: gql`
      ${PAGINATION}
      query {
        additionalFields {
          meta {
            ...PAGINATION
          }
          items {
            name
            required
            _id
          }
        }
      }
    `,
    SEARCH_FIELDS: gql`
      query searchFields($search: String!) {
        additionalFields(search: $search, limit: 100) {
          items {
            name
            required
            _id
          }
        }
        predefinedStaticFields {
          name
        }
      }
    `,
    GET_FIELDS_PAGINATION: gql`
      ${PAGINATION}
      query pagination_fields($page: Int!, $limit: Int!) {
        additionalFields(page: $page, limit: $limit) {
          meta {
            ...PAGINATION
          }
          items {
            name
            required
            _id
          }
        }
      }
    `
  }
};
