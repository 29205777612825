import { i18n } from '@boot/i18n';
import { Icommon } from '@models/general';

export enum ErrorsCodeMessage {
  SERVICE_UNAVAILABLE = 'service_unavailable'
}

export const getMessageError = (error: Error, params?: Icommon): string => {
  const errorMessage = error.message as ErrorsCodeMessage;
  const translated = i18n.t(`errors.${errorMessage as string}`, params);
  if (!translated.includes('errors.')) {
    return translated;
  }
  return i18n.t('errors.service_unavailable');
};

export const getMessageInvalid = (invalid: Error, params?: Icommon): string => {
  const message = invalid.message as ErrorsCodeMessage;
  const translated = i18n.t(`invalid.${message as string}`, params);
  if (!translated.includes('invalid.')) {
    return translated;
  }
  return i18n.t('errors.service_unavailable');
};
