import gql from 'graphql-tag';

export default {
  CREATE_ADMIN: gql`
    mutation createAdmin($createAdminInput: CreateAdminInput!) {
      createAdmin(createAdminInput: $createAdminInput) {
        _id
        type
        name
        email
        use_main_login
        email_forgot_password
        enable_login_with_remote_auth
        disable_user
        group_id
        group_name
        photo
      }
    }
  `,
  DELETE_ADMIN: gql`
    mutation deleteAdmin($ids: [String!]!) {
      deleteAdmin(ids: $ids) {
        success
      }
    }
  `,
  UPDATE_ADMIN: gql`
    mutation updateAdmin($updateAdminInput: UpdateAdminInput!, $id: String!) {
      updateAdmin(updateAdminInput: $updateAdminInput, id: $id) {
        _id
        type
        name
        email
        use_main_login
        email_forgot_password
        enable_login_with_remote_auth
        disable_user
        group_id
        group_name
        photo
      }
    }
  `,
  UPLOAD_AVATAR_STORAGE: gql`
    mutation uploadAvatar($file: UploadAvatarInput!) {
      uploadAvatar(file: $file) {
        _id
        name
      }
    }
  `,
  REMOVE_AVATAR_STORAGE: gql`
    mutation removeStorage($storage: RemoveStorageInput!) {
      removeStorage(storage: $storage) {
        success
      }
    }
  `
};
