import axios from 'axios';

const legacySZ = axios.create({
  baseURL: `${window.location.origin}`,
});

const legacyApi = axios.create({
  baseURL: `${window.location.origin}/api/v4`
});

const serviceApi = axios.create({
  baseURL: `${window.location.origin}/service`,
});

export { legacySZ, legacyApi, serviceApi };
