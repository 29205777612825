import gql from 'graphql-tag';
export const gqlGupshup = {
  query: {
    GET_GUPSHUP_CREDENTIALS: gql`
      query getGupshupCredentialsByChannel(
        $whatsappBrokerSelected: String!
        $channelId: String!
      ) {
        getGupshupCredentialsByChannel(
          whatsappBrokerSelected: $whatsappBrokerSelected
          channelId: $channelId
        ) {
          gupshupApiKey
          gupshupAppName
        }
      }
    `
  }
};
