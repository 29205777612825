import gql from 'graphql-tag';
import { PAGINATION } from '../fragments';

export default {
  GET_ADMINS_PAGINATED: gql`
    ${PAGINATION}
    query getAdminsPaginated($page: Int, $limit: Int, $search: String) {
      getAdminsPaginated(page: $page, limit: $limit, search: $search) {
        meta {
          ...PAGINATION
        }
        items {
          _id
          name
          email
          use_main_login
          email_forgot_password
          enable_login_with_remote_auth
          disable_user
          group_id
          group_name
          photo
        }
      }
    }
  `,
  GET_ADMIN: gql`
    query getAdmin($id: [String!]!) {
      getAdmin(id: $id) {
        _id
        name
        email
        use_main_login
        email_forgot_password
        enable_login_with_remote_auth
        disable_user
        group_id
        group_name
        photo
      }
    }
  `,
  GET_USER_GROUPS: gql`
    query {
      getUserGroups {
        _id
        permissions
        master
        name
        view_by_teams
        general_selected_teams
        individual_selected_teams
        individual_view_by_group
      }
    }
  `
};
