import { query } from 'src/graphql/index.js';
import { setStorageWhitelabel } from 'src/helpers/utils';
import { boot } from 'quasar/wrappers';
import { $apollo } from './apollo';
import useWhiteLabel from '@sz4/sz-components/src/composables/useWhiteLabel';

function sanitizeSzWhiteLabel(whiteLabel: any) {
  const attributesToRemove = [
    'oauth_gmail_client_secret',
    'oauth_office_client_secret'
  ];

  for (const attribute of attributesToRemove) {
    delete whiteLabel[attribute];
  }

  return whiteLabel;
}

export default boot(async () => {
  let whiteLabel = {
    client_id: '',
    theme: {
      menu: '#0C3455',
      modal: '#0C3455',
      button: '#0C3455',
      table: '#0C3455',
      panel: '#0C3455',
      sidebar: '#F5F5F5'
    }
  };

  const { data } = await $apollo.query({ query: query.GET_WHITELABEL });

  const poc = data?.getWhitelabel?.poc ?? null;

  if (poc && poc === 'poc_expired') {
    window.location.href = '/';
    return;
  }

  if (data.getWhitelabel) {
    whiteLabel = data.getWhitelabel;
  }

  setStorageWhitelabel(sanitizeSzWhiteLabel(whiteLabel));

  window.localStorage.setItem('sz_partner', whiteLabel.client_id);

  useWhiteLabel(whiteLabel.theme);
});
