import gql from 'graphql-tag';

export const gqlPermission = {
  query: {
    GET_PERMISSION: gql`
      query getPermission($userId: String!) {
        getUserPermissions(userId: $userId) {
          master
          supportRemote
          accessConfig
          accessData
          teamsGateway
          permissions
        }
      }
    `
  }
};
