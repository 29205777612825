import { WSClient } from 'ws-client';
import { getStorage } from '@helpers/utils';

const storage = getStorage('sz_whitelabel');
const host_ws = storage?.host_ws;

export const socketMessage = (
  options = {},
  callback: (message: any) => void
) => {
  const socket = WSClient(host_ws ? host_ws : window.location.origin, {
    ...options
  });
  socket.on('message', callback);
};
