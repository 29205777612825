export const szRules = {
  register: {
    'rules.admins.name': {
      admins: 'rules.admins.view',
      'admins.add.make': 'rules.admins.add_and_edit',
      'admins.delete': 'rules.admins.delete',
    },
    agents: {
      agents: 'rules.agents.view',
      'agents.add.make': 'rules.agents.add_and_edit',
      'agents.delete': 'rules.agents.delete',
    },
    attendances: {
      campaigns: 'rules.attendances.view',
      'campaigns.add.make': 'rules.attendances.add_and_edit',
      'campaigns.delete': 'rules.attendances.delete',
    },
    additional_fields: {
      contactsFields: 'rules.additional_fields.view',
      'contactsFields.add.make': 'rules.additional_fields.add_and_edit',
      'contactsFields.delete': 'rules.additional_fields.delete',
    },
    channels: {
      channels: 'rules.channels.view',
      'channels.add.make': 'rules.channels.add_and_edit',
      'channels.delete': 'rules.channels.delete',
      'channels.tooggle_receptive': 'rules.channels.tooggle_receptive',
    },
    contacts: {
      contacts: 'rules.contacts.view',
      'contacts.add.make': 'rules.contacts.add_and_edit',
      'contacts.delete': 'rules.contacts.delete',
    },
    flow: {
      flow: 'rules.flow.view',
      'flow.add.make': 'rules.flow.add_and_edit',
      'flow.duplicate': 'rules.flow.duplicate',
      'flow.delete': 'rules.flow.delete',
      'flow.import': 'rules.flow.import',
      'flow.export': 'rules.flow.export',
    },
    contacts_groups: {
      contactsGroups: 'rules.contacts_groups.view',
      'contactsGroups.add.make': 'rules.contacts_groups.add_and_edit',
      'contactsGroups.delete': 'rules.contacts_groups.delete',
    },
  },
  configurations: {
    app: {
      app: 'rules.app.view',
      'app.add.make': 'rules.app.edit',
    },
    artificial_intelligence:{
      nlp: 'rules.artificial_intelligence.view',
      'nlp.add.make': 'rules.artificial_intelligence.add_and_edit',
      'nlp.delete': 'rules.artificial_intelligence.delete'
    },
    context:{
      context: 'rules.context.view',
      'context.add.make': 'rules.context.add_and_edit',
      'context.delete': 'rules.context.delete'
    },
    storage: {
      storage: 'rules.storage.view',
      'storage.disk': 'rules.storage.disk',
      'storage.delete': 'rules.storage.delete',
      'storage.delete.type': 'rules.storage.delete_type',
    },
    word_filter: {
      wordFilter: 'rules.word_filter.view',
      'wordFilter.add.make': 'rules.word_filter.add_and_edit',
      'wordFilter.delete': 'rules.word_filter.delete',
    },
    group_user: {
      group: 'rules.group_user.view',
      'group.add.make': 'rules.group_user.add_and_edit',
      'group.delete': 'rules.group_user.delete',
    },
    scheduler: {
      timeGroup: 'rules.scheduler.view',
      'timeGroup.add.make': 'rules.scheduler.add_and_edit',
      'timeGroup.delete': 'rules.scheduler.delete',
    },
    hsm: {
      hsm: 'rules.hsm.view',
      'hsm.add.make': 'rules.hsm.add_and_edit',
    },
    hsm_template: {
      hsm_template: 'rules.hsm_template.view',
      'hsm_template.add.make': 'rules.hsm_template.add_and_edit_and_delete',
    },
    indicators: {
      dashboard: 'rules.indicators.view',
      'dashboard.add.make': 'rules.indicators.add_and_edit',
      'dashboard.delete': 'rules.indicators.delete',
    },
    monitoring: {
      monitoring: 'rules.monitoring.enable',
    },
    blocked_numbers: {
      blockedNumbers: 'rules.blocked_numbers.view',
      'blockedNumbers.add.make': 'rules.blocked_numbers.add_and_edit',
      'blockedNumbers.delete': 'rules.blocked_numbers.delete',
    },
    pauses: {
      pauses: 'rules.pauses.view',
      'pauses.add.make': 'rules.pauses.add_and_edit',
      'pauses.delete': 'rules.pauses.delete',
    },
    security_privacy: {
      securityPrivacy: 'rules.security_privacy.view',
      'securityPrivacy.add.make': 'rules.security_privacy.edit',
    },
    remote_support: {
      remoteSupport: 'rules.remote_support.view',
      'remoteSupport.add.make': 'rules.remote_support.edit',
    },
    tabulations: {
      tabulations: 'rules.tabulations.view',
      'tabulations.add.make': 'rules.tabulations.add_and_edit',
      'tabulations.delete': 'rules.tabulations.delete',
    },
    custom_variables: {
      customVariables: 'rules.custom_variables.view',
      'customVariables.add.make': 'rules.custom_variables.add_and_edit',
      'customVariables.delete': 'rules.custom_variables.delete',
    },
  },
  services: {
    api: {
      apiInfo: 'rules.api.view',
      'apiInfo.app.add.make': 'rules.api.add_and_edit',
      'apiInfo.app.delete': 'rules.api.delete',
    },
    call_center: {
      callcenter: 'rules.call_center.view',
      'callcenter.config': 'rules.call_center.config',
      'callcenter.delete': 'rules.call_center.delete',
    },
    batch_delivery: {
      batchDelivery: 'rules.batch_delivery.view',
      'batchDelivery.add.make': 'rules.batch_delivery.add_and_edit',
      'batchDelivery.delete': 'rules.batch_delivery.delete',
    },
    import_contacts: {
      contactsImport: 'rules.import_contacts.view',
      'contactsImport.add.make': 'rules.import_contacts.add_and_edit',
      'contactsImport.delete': 'rules.import_contacts.delete',
    },
    marketplace:{
      marketplace: 'rules.marketplace.view',
      'marketplace.add.make': 'rules.marketplace.add_and_edit',
    },
    multichannel: {
      contactUs: 'rules.multichannel.view',
      'contactUs.add.make': 'rules.multichannel.add_and_edit',
      'contactUs.delete': 'rules.multichannel.delete',
    },
    providers: {
      integrations: 'rules.providers.view',
      'integrations.add.make': 'rules.providers.add_and_remove',
    },
    rest: {
      rest: 'rules.rest.view',
      'rest.add.make': 'rules.rest.add_and_edit',
      'rest.delete': 'rules.rest.delete',
    },
    rdstation: {
      rdstation: 'rules.rdstation.view',
      'rdstation.add.make': 'rules.rdstation.add_and_edit',
      'rdstation.delete': 'rules.rdstation.delete',
    },
  },
  reports: {
    'reports.attendances': 'rules.attendances.name',
    'reports.agents': 'rules.agents.name',
    'reports.audits': 'rules.reports.audits',
    'reports.updates': 'rules.reports.updates',
    'reports.messages': 'rules.reports.conversations',
    'reports.inputs': 'rules.reports.inputs',
    'reports.batchDelivery': 'rules.batch_delivery.name',
    'reports.exports': 'rules.reports.exports',
    'reports.noteAttendances': 'rules.reports.note_attendances',
    'reports.notifications': 'rules.reports.notifications',
    'reports.sentimentAnalysis': 'rules.reports.sentiment_analysis',
    'reports.license': 'rules.reports.messages_extract',
    'reports.premium': 'rules.reports.premium',
  },
};
