import Accordion from '@components/Accordion.vue';
import Announcement from '@components/Announcement.vue';
import Badge from '@components/Badge.vue';
import Breadcrumb from '@components/Breadcrumb.vue';
import BtnActions from '@components/BtnActions.vue';
import BtnLanguage from '@components/BtnLanguage.vue';
import Card from '@components/Card.vue';
import CardSection from '@components/CardSection.vue';
import List from '@components/List.vue';
import Item from '@components/Item.vue';
import ItemSection from '@components/ItemSection.vue';
import Page from '@components/Page.vue';
import PageContainer from '@components/PageContainer.vue';
import EmojiPicker from '@components/EmojiPicker.vue';
import ItemLabel from '@components/ItemLabel.vue';
import CardActions from '@components/CardActions.vue';
import ColorPicker from '@components/ColorPicker.vue';
import Contact from '@components/Contact.vue';
import Toolbar from '@components/Toolbar.vue';
import ToolbarTitle from '@components/ToolbarTitle.vue';
import DropdownChannel from '@components/DropdownChannel.vue';
import Fieldset from '@components/Fieldset.vue';
import Icon from '@components/Icon.vue';
import InputFile from '@components/InputFile.vue';
import Loading from '@components/Loading.vue';
import NoData from '@components/NoData.vue';
import Note from '@components/Note.vue';
import Pagination from '@components/Pagination.vue';
import PopupProxy from '@components/PopupProxy.vue';
import Profile from '@components/Profile.vue';
import Search from '@components/Search.vue';
import SelectLanguage from '@components/SelectLanguage.vue';
import SelectPlatform from '@components/SelectPlatform.vue';
import SimpleTable from '@components/SimpleTable.vue';
import Stepper from '@components/Stepper.vue';
import Toggle from '@components/Toggle.vue';
import Separator from '@components/Separator.vue';
import Sidebar from '@components/Sidebar.vue';
import WithoutPermission from '@components/WithoutPermission.vue';
import Layout from '@layout/Layout.vue';
import Main from '@layout/Main.vue';
import { boot } from 'quasar/wrappers';
import CountryFlag from 'vue-country-flag';

// SZ Components import
import {
  SzAccordion,
  SzAnnouncement,
  SzAvatar,
  SzAvatarTable,
  SzBadge,
  SzBreadcrumb,
  SzBtn,
  SzBtnActions,
  SzBtnLanguage,
  SzCardSection,
  SzColorPicker,
  SzContact,
  SzFieldset,
  SzHeader,
  SzIcon,
  SzInputCheckbox,
  SzInputDense,
  SzInputFile,
  SzInputRadio,
  SzItem,
  SzItemLabel,
  SzItemSection,
  SzLoading,
  SzList,
  SzModal,
  SzModalFooter,
  SzNoData,
  SzNote,
  SzPage,
  SzPagination,
  SzProfile,
  SzPopupProxy,
  SzSearch,
  SzSelectDense,
  SzSeparator,
  SzSideModal,
  SzSimpleTable,
  SzStepper,
  SzTable,
  SzTd,
  SzToggle,
  SzTooltip,
  SzTree,
  SzWithoutPermission,
  SzCard,
  SzCardGroup,
  SzCardDialingPermission,
  SzCardRingGroup,
  SzGrouperAvatar,
  SzAvatarTree,
  SzTableNoData,
  SzTableGroup,
  SzToolbar,
  SzToolbarTitle,
  SzPageContainer,
  SzSpace,
  SzCardSegment,
  SzForm,
  SzPopup,
  SzCardMessage
} from '@sz4/sz-components';

import SzUnleash from '../stories/components/SzUnleash.vue';

export default boot(({ Vue }) => {
  Vue.component('SzUnleash', SzUnleash);
  Vue.component('Accordion', Accordion);
  Vue.component('Announcement', Announcement);
  Vue.component('Badge', Badge);
  Vue.component('Breadcrumb', Breadcrumb);
  Vue.component('BtnActions', BtnActions);
  Vue.component('BtnLanguage', BtnLanguage);
  Vue.component('Card', Card);
  Vue.component('EmojiPicker', EmojiPicker);
  Vue.component('CardActions', CardActions);
  Vue.component('CardSection', CardSection);
  Vue.component('ColorPicker', ColorPicker);
  Vue.component('Contact', Contact);
  Vue.component('DropdownChannel', DropdownChannel);
  Vue.component('Fieldset', Fieldset);
  Vue.component('Icon', Icon);
  Vue.component('List', List);
  Vue.component('Item', Item);
  Vue.component('ItemLabel', ItemLabel);
  Vue.component('ItemSection', ItemSection);
  Vue.component('CountryFlag', CountryFlag);
  Vue.component('InputFile', InputFile);
  Vue.component('Loading', Loading);
  Vue.component('NoData', NoData);
  Vue.component('Note', Note);
  Vue.component('Pagination', Pagination);
  Vue.component('PopupProxy', PopupProxy);
  Vue.component('Profile', Profile);
  Vue.component('SimpleTable', SimpleTable);
  Vue.component('Search', Search);
  Vue.component('PageContainer', PageContainer);
  Vue.component('Page', Page);
  Vue.component('SelectLanguage', SelectLanguage);
  Vue.component('SelectPlatform', SelectPlatform);
  Vue.component('Separator', Separator);
  Vue.component('Stepper', Stepper);
  Vue.component('Sidebar', Sidebar);
  Vue.component('Toolbar', Toolbar);
  Vue.component('ToolbarTitle', ToolbarTitle);
  Vue.component('Toggle', Toggle);
  Vue.component('WithoutPermission', WithoutPermission);

  // layouts
  Vue.component('Main', Main);
  Vue.component('Layout', Layout);

  // SZ Components
  Vue.component('SzAccordion', SzAccordion);
  Vue.component('SzAnnouncement', SzAnnouncement);
  Vue.component('SzAvatar', SzAvatar);
  Vue.component('SzLoading', SzLoading);
  Vue.component('SzAvatarTable', SzAvatarTable);
  Vue.component('SzInputRadio', SzInputRadio);
  Vue.component('SzItemLabel', SzItemLabel);
  Vue.component('SzAvatarTree', SzAvatarTree);
  Vue.component('SzBadge', SzBadge);
  Vue.component('SzBreadcrumb', SzBreadcrumb);
  Vue.component('SzBtnActions', SzBtnActions);
  Vue.component('SzBtnLanguage', SzBtnLanguage);
  Vue.component('SzBtn', SzBtn);
  Vue.component('SzModal', SzModal);
  Vue.component('SzCard', SzCard);
  Vue.component('SzInputDense', SzInputDense);
  Vue.component('SzInputCheckbox', SzInputCheckbox);
  Vue.component('SzCardDialingPermission', SzCardDialingPermission);
  Vue.component('SzCardGroup', SzCardGroup);
  Vue.component('SzCardSection', SzCardSection);
  Vue.component('SzItem', SzItem);
  Vue.component('SzCardSegment', SzCardSegment);
  Vue.component('SzColorPicker', SzColorPicker);
  Vue.component('SzContact', SzContact);
  Vue.component('SzFieldset', SzFieldset);
  Vue.component('SzForm', SzForm);
  Vue.component('SzList', SzList);
  Vue.component('SzHeader', SzHeader);
  Vue.component('SzItemSection', SzItemSection);
  Vue.component('SzIcon', SzIcon);
  Vue.component('SzInputFile', SzInputFile);
  Vue.component('SzModalFooter', SzModalFooter);
  Vue.component('SzNoData', SzNoData);
  Vue.component('SzNote', SzNote);
  Vue.component('SzPopup', SzPopup);
  Vue.component('SzPage', SzPage);
  Vue.component('SzPagination', SzPagination);
  Vue.component('SzProfile', SzProfile);
  Vue.component('SzPopupProxy', SzPopupProxy);
  Vue.component('SzSimpleTable', SzSimpleTable);
  Vue.component('SzSearch', SzSearch);
  Vue.component('SzSeparator', SzSeparator);
  Vue.component('SzSelectDense', SzSelectDense);
  Vue.component('SzStepper', SzStepper);
  Vue.component('SzTable', SzTable);
  Vue.component('SzTd', SzTd);
  Vue.component('SzToggle', SzToggle);
  Vue.component('SzTooltip', SzTooltip);
  Vue.component('SzTree', SzTree);
  Vue.component('SzWithoutPermission', SzWithoutPermission);
  Vue.component('SzSideModal', SzSideModal);
  Vue.component('SzCardRingGroup', SzCardRingGroup);
  Vue.component('SzGrouperAvatar', SzGrouperAvatar);
  Vue.component('SzTableNoData', SzTableNoData);
  Vue.component('SzTableGroup', SzTableGroup);
  Vue.component('SzPageContainer', SzPageContainer);
  Vue.component('SzToolbar', SzToolbar);
  Vue.component('SzToolbarTitle', SzToolbarTitle);
  Vue.component('SzSpace', SzSpace);
  Vue.component('SzCardMessage', SzCardMessage);
});
