import gql from 'graphql-tag';
import {
  CHANNELS,
  PAGINATION,
  HUB_FACEBOOK,
  WEBCHAT_CUSTOMIZATION_V2,
  WEBCHAT_CUSTOMIZATION_V1
} from './fragments';

export const gqlChannel = {
  query: {
    GET_CHANNELS: gql`
      ${PAGINATION}
      ${CHANNELS}
      ${HUB_FACEBOOK}
      query {
        channels {
          meta {
            ...PAGINATION
          }
          items {
            ...CHANNELS
          }
        }
        hubFacebook(filters: {}) {
          ...HUB_FACEBOOK
        }
        facebookAdAccounts {
          _id
          name
          account_id
        }
        getBSPForticsNumbers {
          verified_name
          display_phone_number
          api_key
        }
        getTechProviderNumbers {
          verified_name
          display_phone_number
          api_key
        }
        getPlatformsDefault {
          name
          platform
          placeholder
          icon
          in_channel_pack
          componentsBlockedInFlow
        }
        getBSPs {
          name
          ref
          headers
        }
        additionalFields {
          meta {
            ...PAGINATION
          }
          items {
            name
            required
            _id
          }
        }
      }
    `,
    GET_CHANNELS_SELECT: gql`
      ${PAGINATION}
      query {
        channels {
          meta {
            ...PAGINATION
          }
          items {
            platform
            description
            number
            whatsapp_broker
            _id
          }
        }
      }
    `,
    GET_SEARCH_CHANNELS_SELECT: gql`
      query searchChannelSelect($search: String!) {
        channels(search: $search) {
          meta {
            ...PAGINATION
          }
          items {
            platform
            description
            _id
          }
        }
      }
    `,
    SEARCH_CHANNELS: gql`
      ${PAGINATION}
      ${CHANNELS}
      ${WEBCHAT_CUSTOMIZATION_V2}
      ${WEBCHAT_CUSTOMIZATION_V1}
      query searchChannel($search: String!) {
        channels(search: $search) {
          meta {
            ...PAGINATION
          }
          items {
            ...CHANNELS
            _id
            webchat_version
            description
            receptive
            botname
            platform
            updated_at
            provider
            whatsapp_broker
            webchat_version
            options {
              ...WEBCHAT_CUSTOMIZATION_V2
            }
            ...WEBCHAT_CUSTOMIZATION_V1
            flow {
              name
            }
            flow_id
            number
            status
            enabled
            teams_gtw
          }
        }
      }
    `,
    GET_WEBHOOK_URL: gql`
      query WebhookUrlBSP($channelId: String!, $broker: String!) {
        getWebhookUrlBSP(channelId: $channelId, broker: $broker)
      }
    `,
    GET_CREDENTIALS_OAUTH2: gql`
      query CredentialsOauth2($channelId: String!, $code: String!) {
        getCredentialsOauth2(channelId: $channelId, code: $code)
      }
    `,
    CHECK_ACCOUNT: gql`
      query isInstagramBusiness($channelId: String!) {
        isInstagramBusiness(channelId: $channelId) {
          status
          message
        }
      }
    `,
    GET_RA_PAGES: gql`
      query reclameAquiGetPages($username: String!, $password: String!) {
        reclameAquiGetPages(username: $username, password: $password) {
          status
          message
          pages {
            name
            companyId
          }
        }
      }
    `,
    GET_CHANNELS_PAGINATION: gql`
      ${PAGINATION}
      ${CHANNELS}
      query pagination_channel($page: Int!, $limit: Int!) {
        channels(page: $page, limit: $limit) {
          meta {
            ...PAGINATION
          }
          items {
            ...CHANNELS
          }
        }
      }
    `
  },
  mutation: {
    ENABLE_RECEPTIVE: gql`
      mutation enableReceptive($channelId: String!) {
        toggleReceptiveChannel(channelId: $channelId) {
          description
        }
      }
    `,
    ENABLE_CHANNEL: gql`
      mutation enableChannel($channelId: String!) {
        toggleEnableChannel(channelId: $channelId) {
          success
          description
          error {
            message
            stack_trace
          }
        }
      }
    `,
    RESTART_CHANNEL: gql`
      mutation enableChannel($id: String!) {
        restartChannel(id: $id)
      }
    `,
    GENERATE_TOKEN: gql`
      mutation generateAuthToken($id: String!) {
        generateAuthToken(channelId: $id) {
          _id
        }
      }
    `,
    ADD_RULES_EMAIL: gql`
      mutation addRulesEmail($rules: RulesEmailInput!, $id: String!) {
        addRulesEmail(rules: $rules, id: $id) {
          _id
        }
      }
    `,
    CREATE_CHANNELS: gql`
      mutation createChannel($channel: CreateChannelInput!) {
        createChannel(channel: $channel) {
          description
          flow_id
          authUrl
        }
      }
    `,
    ADD_CUSTOMIZATION_V1: gql`
      mutation addCustomizationWebchatV1(
        $options: CustomizationWebchatV1Input!
        $id: String!
      ) {
        addCustomizationWebchatV1(options: $options, id: $id) {
          _id
        }
      }
    `,
    ADD_CUSTOMIZATION_V2: gql`
      mutation addCustomizationWebchatV2(
        $options: CustomizationWebchatV2Input!
        $id: String!
      ) {
        addCustomizationWebchatV2(options: $options, id: $id) {
          _id
        }
      }
    `,
    UPDATE_CHANNELS: gql`
      mutation updateChannel($channel: UpdateChannelInput!, $id: String!) {
        updateChannel(channel: $channel, id: $id) {
          description
          authUrl
          status
        }
      }
    `,
    DELETE_CHANNELS: gql`
      mutation removeChannel($channel: RemoveChannelInput!) {
        removeChannel(channel: $channel) {
          success
          message
        }
      }
    `
  }
};
